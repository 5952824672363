const MOBILE_WIDTH_MIN = 0;
const MOBILE_WIDTH_MAX = 767.99;
const TABLET_WIDTH_MIN = 768;
const TABLET_WIDTH_MAX = 1619.99;
const DESKTOP_WIDTH_MIN = 1620;

export {
  MOBILE_WIDTH_MIN,
  MOBILE_WIDTH_MAX,
  TABLET_WIDTH_MIN,
  TABLET_WIDTH_MAX,
  DESKTOP_WIDTH_MIN,
};
