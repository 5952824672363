import stepImage1 from '../assets/images/roadmap/blockchain.jpg';
import stepImage2 from '../assets/images/roadmap/attias.jpg';
import stepImage3 from '../assets/images/roadmap/bitcoin.jpg';
import stepImage4 from '../assets/images/roadmap/mining.jpg';

const STEPS = [
  {
    number: 1,
    title: 'labore et dolore',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    img: {
      src: stepImage1,
      alt: 'Blockchain',
    },
  },
  {
    number: 2,
    title: 'Ut enim ad minim',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    img: {
      src: stepImage2,
      alt: 'Attias',
    },
  },
  {
    number: 3,
    title: 'Lorem ipsum dolor',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    img: {
      src: stepImage3,
      alt: 'Bitcoin',
    },
  },
  {
    number: 4,
    title: 'Ut enim ad minim',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
    img: {
      src: stepImage4,
      alt: 'Mining',
    },
  },
];

export { STEPS };
