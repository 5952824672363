import * as React from 'react';

import Container from '../../../common/container';
import TowerIcon from '../../../assets/icons/tower.svg';
import BookIcon from '../../../assets/icons/book.svg';
import HexagonIcon from '../../../assets/icons/hexagon.svg';
import DocumentationIcon from '../../../assets/icons/documentation.svg';

import * as s from './styles.module.scss';

const RESOURCES = [
  { icon: <TowerIcon />, description: 'Edunomic Academy' },
  { icon: <BookIcon />, description: 'Cyber Bytes Academy' },
  { icon: <HexagonIcon />, description: 'STEAMBridge' },
  { icon: <DocumentationIcon />, description: 'Documentation' },
];

const EducationalResources = () => {
  return (
    <section className={s.educationalResources}>
      <Container>
        <div className={s.educationalResources_content}>
          <h3 className={s.educationalResources_title}>
            Educational Resources
          </h3>
          <div className={s.educationalResources_list}>
            {RESOURCES?.map((resource) => (
              <div
                className={s.educationalResources_item}
                key={resource.description}
              >
                <div className={s.educationalResources_icon}>
                  {resource.icon}
                </div>
                <p className={s.educationalResources_description}>
                  {resource.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default EducationalResources;
