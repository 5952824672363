import * as React from 'react';

import Layout from '../../layout';
import Hero from '../../components/services/hero';
import Core from '../../components/services/core';
import EcosystemTools from '../../components/services/ecosystem-tools';
import EducationalResources from '../../components/services/educational-resources';
import Roadmap from '../../components/services/roadmap';

import { STEPS } from '../../data/STEPS';

const TechnologyPage = () => {
  return (
    <Layout>
      <Hero />
      <Core />
      <EcosystemTools />
      <EducationalResources />
      <Roadmap steps={STEPS} />
    </Layout>
  );
};

export default TechnologyPage;
