import * as React from 'react';

import coreImg from '../../../assets/images/core.jpg';

import * as s from './styles.module.scss';

const Core = () => {
  return (
    <section className={s.core}>
      <div className={s.core_content}>
        <div className={s.core_wrapper}>
          <h2 className={s.core_title}>Edunomic Chain</h2>
          <p className={s.core_text}>
          Edunomic Chain is a Layer-1, EVM-compatible blockchain built
          for the growing world of EdTech.

          As the first blockchain built with Education in mind, we aim to pave
          the way for real blockchain solutions such as Proof of Learning,
          secure storage for ERP, CRM, LMS, and SIS ecosystems, accreditation and credentials management, and more.
          By bringing institutions together to build a blockchain focused on
          Education, we can build a safe, reliable ecosystem geared toward student
          success, safety, and global education resilience.
          </p>
        </div>
        <img className={s.core_img} src={coreImg} alt="Core" />
      </div>
    </section>
  );
};

export default Core;
