import * as React from 'react';
import PropTypes from 'prop-types';

import Step from '../../../common/step';
import useWindowSize from '../../../hooks/useWindowSize';
import { MOBILE_WIDTH_MAX, TABLET_WIDTH_MIN } from '../../../constants/size';

import * as s from './styles.module.scss';

const Roadmap = ({ steps }) => {
  const { width } = useWindowSize();

  return (
    <section className={s.roadmap}>
      <h3 className={s.roadmap_title}>The Roadmap (Under Construction)</h3>
      {width >= TABLET_WIDTH_MIN && (
        <div className={s.roadmap_content}>
          <div className={s.roadmap_content_divider}>&nbsp;</div>
          {steps?.map((step) => (
            <div className={s.roadmap_content_item} key={step.number}>
              <img src={step.img.src} alt={step.img.alt} />
              <Step
                itemNumber={step.number}
                description={step.description}
                title={step.title}
                className={s.roadmap_content_step}
              />
            </div>
          ))}
        </div>
      )}
      {width <= MOBILE_WIDTH_MAX && (
        <div className={s.roadmap_contentMobile}>
          {steps.map((step) => (
            <div key={step.number}>
              <img
                src={step.img.src}
                alt={step.img.alt}
                width="100%"
                height="100%"
              />
              <Step
                itemNumber={step.number}
                description={step.description}
                title={step.title}
              />
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

Roadmap.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      img: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    })
  ),
};

export default Roadmap;
