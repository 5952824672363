import * as React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import * as s from './styles.module.scss';

const Step = ({ itemNumber, title, description, className }) => {
  const specNumber = itemNumber <= 10 ? '0' + itemNumber : itemNumber;

  return (
    <div className={cn(s.step, className)}>
      <span className={s.step_itemNumber}>{specNumber}</span>
      <div className={s.step_content}>
        <h5 className={s.step_title}>{title}</h5>
        <p className={s.step_description}>{description}</p>
      </div>
    </div>
  );
};

Step.propTypes = {
  itemNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Step;
